import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

const markets = [
  'Atlanta',
  'Austin',
  'Baltimore',
  'Charlotte',
  'Chicago',
  'Cincinnati',
  'Colorado Springs',
  'Dallas',
  'Denver',
  'Fort Collins',
  'Fort Lauderdale',
  'Houston',
  'Indianapolis',
  'Jacksonville',
  'Las Vegas',
  'Los Angeles',
  'Miami',
  'Milwaukee',
  'New York',
  'North Carolina',
  'Northern Virginia',
  'Oklahoma City',
  'Orlando',
  'Philadelphia',
  'Phoenix',
  'Raleigh',
  'San Antonio',
  'St. Louis',
  'Tampa',
]

export default function AddLedgerItemForm(props) {
  const handleChange = e => {
    props.updateForm({
      value: e.target.value,
      name: e.target.name,
    })
  }

  const onSubmit = event => {
    event.preventDefault()
    props.submitForm()
  }

  return (
    <form noValidate autoComplete="off" style={{ textAlign: 'left' }} onSubmit={onSubmit}>
      <div>
        <FormControl component="fieldset" margin="normal" fullWidth={true}>
          <TextField
            id="paymentId"
            label="PaymentId ID"
            variant="outlined"
            value={props.paymentId}
            onChange={handleChange}
            name="paymentId"
            disabled={true}
          />
        </FormControl>
      </div>

      <div>
        <FormControl component="fieldset" margin="normal" fullWidth={true}>
          <TextField
            type="number"
            id="amount"
            label="Amount"
            variant="outlined"
            onChange={handleChange}
            name="amount"
            fullWidth={true}
          />
        </FormControl>
      </div>

      <div>
        <FormControl component="fieldset" margin="normal" fullWidth={true}>
          <FormLabel component="legend">Reference Type</FormLabel>
          <RadioGroup name="referenceType" value={props.referenceType} onChange={handleChange}>
            <FormControlLabel value="NONE" control={<Radio />} label="None" />
            <FormControlLabel value="ROUTE" control={<Radio />} label="Route" />
            <FormControlLabel value="INCENTIVE" control={<Radio />} label="Incentive" />
          </RadioGroup>
        </FormControl>
      </div>

      <div>
        <FormControl component="fieldset" margin="normal" fullWidth={true}>
          <TextField
            id="referenceId"
            label="Reference ID"
            variant="outlined"
            name="referenceId"
            onChange={handleChange}
          />
        </FormControl>
      </div>

      <div>
        <FormControl component="fieldset" margin="normal" fullWidth={true}>
          <FormLabel component="legend">Category</FormLabel>
          <RadioGroup name="category" value={props.category} onChange={handleChange}>
            <FormControlLabel value="ROUTE_EARNING" control={<Radio />} label="Route Earning" />
            <FormControlLabel value="ROUTE_ADJUSTMENT" control={<Radio />} label="Route Adjustment" />
            <FormControlLabel value="OAI_ADJUSTMENT" control={<Radio />} label="OAI Adjustment" />
            <FormControlLabel value="STANDBY_EARNING" control={<Radio />} label="Standby Earning" />
            <FormControlLabel value="PICKUP_EARNING" control={<Radio />} label="Pickup Earning" />
            <FormControlLabel value="INCENTIVES" control={<Radio />} label="Incentive" />
            <FormControlLabel value="REFERRAL" control={<Radio />} label="Referral" />
            <FormControlLabel value="MISC" selected={true} control={<Radio />} label="Misc." />
            <FormControlLabel
              value="DRIVER_RESTITUTION"
              selected={true}
              control={<Radio />}
              label="Driver Restitution"
            />
          </RadioGroup>
        </FormControl>
      </div>

      <div>
        <FormControl component="fieldset" margin="normal" fullWidth={true}>
          <FormLabel component="legend">Market</FormLabel>
          <RadioGroup name="market" value={props.market} onChange={handleChange}>
            {markets.map(m => (
              <FormControlLabel value={m} control={<Radio />} label={m} />
            ))}
          </RadioGroup>
        </FormControl>
      </div>

      <div>
        <FormControl component="fieldset" margin="normal" fullWidth={true}>
          <TextField
            onChange={handleChange}
            id="note"
            label="Note"
            multiline
            rows={2}
            defaultValue=""
            variant="outlined"
            name="note"
          />
        </FormControl>
      </div>

      <FormControl component="fieldset" margin="normal" fullWidth={true}>
        <Button type="submit" variant="outlined" color="primary">
          Add Ledger Item
        </Button>
      </FormControl>
    </form>
  )
}
