import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'

export default function Message({ message, severity, onClose }) {
  return (
    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={message !== null}>
      <Alert variant="filled" severity={severity} onClose={onClose}>
        {message}
      </Alert>
    </Snackbar>
  )
}
