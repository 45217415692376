import React from 'react'
import { Auth } from 'aws-amplify'
import { useRouteMatch } from 'react-router'
import { Link, useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Chip from '@material-ui/core/Chip'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import PaymentIcon from '@material-ui/icons/Payment'
import BlockIcon from '@material-ui/icons/Block'
import PauseIcon from '@material-ui/icons/Pause'
import AssignmentIcon from '@material-ui/icons/Assignment'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate'
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned'
import ImportContacts from '@material-ui/icons/ImportContacts'
import TimerIcon from '@material-ui/icons/Timer'
import UpdateIcon from '@material-ui/icons/Update'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import AddIcon from '@material-ui/icons/Add'

import { usePaymentStatusCounts } from '../hooks/usePaymentStatusCounts'

const drawerWidth = 240
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: 'calc(100vh - 160px)',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
    height: '100%',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },
}))

function ListItemLink(props) {
  const { icon, primary, to, count, ...extraProps } = props
  const match = useRouteMatch(to)

  const renderLink = React.useMemo(
    () => React.forwardRef((itemProps, ref) => <Link to={to} ref={ref} {...itemProps} />),
    [to]
  )

  return (
    <ListItem button selected={!!match} component={renderLink} {...extraProps}>
      {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
      <ListItemText inset={!icon} primary={primary} />
      <ListItemSecondaryAction>{count ? <Chip size="small" label={count} /> : null}</ListItemSecondaryAction>
    </ListItem>
  )
}

export default function ClippedDrawer(props) {
  const classes = useStyles()
  let history = useHistory()
  const paymentStatusCounts = usePaymentStatusCounts()

  const signOut = async () => {
    try {
      await Auth.signOut({ global: true })
      history.push('/')
      window.location.reload()
    } catch (error) {
      console.log('error signing out: ', error)
    }
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" noWrap>
            Seshat: Driver Payment Service
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          <List>
            <ListItemLink primary={'Payments'} icon={<PaymentIcon />} to={'/payments'} />
            <List dense className={classes.nested}>
              <ListItemLink
                primary={'Open'}
                count={paymentStatusCounts.OPEN}
                icon={<ImportContacts />}
                to={'/payments/status/open'}
              />
              <ListItemLink
                primary={'Under Review'}
                count={paymentStatusCounts.UNDER_REVIEW}
                icon={<AssignmentIcon />}
                to={'/payments/status/under_review'}
              />
              <ListItemLink
                primary={'Held'}
                count={paymentStatusCounts.HELD}
                icon={<PauseIcon />}
                to={'/payments/status/held'}
              />
              <ListItemLink
                primary={'Cancelled'}
                count={paymentStatusCounts.CANCELLED}
                icon={<BlockIcon />}
                to={'/payments/status/cancelled'}
              />
              <ListItemLink
                primary={'Queued'}
                count={paymentStatusCounts.ENQUEUED_FOR_RELEASE}
                icon={<TimerIcon />}
                to={'/payments/status/enqueued_for_release'}
              />
              <ListItemLink
                primary={'Submitted'}
                count={paymentStatusCounts.SUBMITTED}
                icon={<AssignmentReturnedIcon />}
                to={'/payments/status/submitted'}
              />
              <ListItemLink
                primary={'Processing'}
                count={paymentStatusCounts.PROCESSING}
                icon={<UpdateIcon />}
                to={'/payments/status/processing'}
              />
              <ListItemLink
                primary={'Failed'}
                count={paymentStatusCounts.FAILED}
                icon={<AssignmentLateIcon />}
                to={'/payments/status/failed'}
              />
              <ListItemLink
                primary={'Completed'}
                count={paymentStatusCounts.COMPLETED}
                icon={<AssignmentTurnedInIcon />}
                to={'/payments/status/completed'}
              />
            </List>
            <ListItemLink primary={'Add Ledger Item(s)'} icon={<AddIcon />} to={'/add-ledger-item'} />
          </List>
          <Divider />
          <List>
            <ListItem button onClick={signOut}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </div>
      </Drawer>
      <main className={classes.content}>
        <Toolbar />
        {props.children}
      </main>
    </div>
  )
}
