import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}))

export function ActionAlert(props) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {/* <Alert onClose={() => {}}>This is a success alert — check it out!</Alert> */}
      <Alert
        style={{ textAlign: 'left' }}
        severity={props.severity}
        action={
          <Button color="inherit" size="small" onClick={props.onClose}>
            CLOSE
          </Button>
        }
      >
        {props.message}
      </Alert>
    </div>
  )
}
