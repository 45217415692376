import { useState, useCallback, useEffect } from 'react'
import { API } from 'aws-amplify'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

import * as queries from '../graphql/queries'
import * as mutations from '../graphql/mutations'
import PaymentsGrid from '../components/PaymentsGrid'
import Message from '../components/Message'
import { ACH_EXPEDITED, ACH_NEXT_DAY, ACH_SAME_DAY } from '../config/enum/Constant'
import { parseMuiFilterModel } from '../utils/filterUtils'
import { usePaymentStatusCounts } from '../hooks/usePaymentStatusCounts'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  actionButtons: {
    marginBottom: theme.spacing(2),
  },
}))

function ReleasePaymentButtons({ disabled, count, onClick }) {
  const styles = useStyles()
  const [paymentOption, setPaymentOption] = useState(ACH_EXPEDITED)
  const handlePaymentOption = event => {
    setPaymentOption(event.target.value)
  }

  return (
    <Grid container className={styles.actionButtons}>
      <Grid item lg={4} md={1} sm={false} xs={false} />
      <Grid item lg={4} md={6} sm={6} xs={12}>
        <Button variant="contained" color="primary" disabled={disabled} onClick={() => onClick(paymentOption)}>
          Release ({count}) UNDER_REVIEW Payments
        </Button>
      </Grid>
      <Grid item lg={2} md={4} sm={6} xs={12}>
        <Select value={paymentOption} onChange={handlePaymentOption}>
          <MenuItem value={ACH_EXPEDITED}>ACH: 2-3 business days</MenuItem>
          <MenuItem value={ACH_NEXT_DAY}>ACH: 1 business day</MenuItem>
          <MenuItem value={ACH_SAME_DAY}>ACH: Same business day</MenuItem>
        </Select>
      </Grid>
      <Grid item lg={2} md={1} sm={false} xs={false} />
    </Grid>
  )
}

function ReviewPaymentButtons({ disabled, count, onClick }) {
  const styles = useStyles()
  return (
    <Button variant="contained" color="primary" disabled={disabled} onClick={onClick} className={styles.actionButtons}>
      Start Review of ({count}) OPEN Payments
    </Button>
  )
}

const cleanPayment = ({ id, createdAt, updatedAt, ...rest }) => ({
  id: parseInt(id),
  createdAt: new Date(createdAt),
  updatedAt: new Date(updatedAt),
  ...rest,
})

export const Payments = ({ status }) => {
  const styles = useStyles()
  const paymentStatusCounts = usePaymentStatusCounts()

  const [payments, setPayments] = useState([])
  const [paymentsLoaded, setPaymentsLoaded] = useState(false)
  const [actionRunning, setActionRunning] = useState(false)
  const [message, setMessage] = useState(null)
  const [messageSeverity, setMessageSeverity] = useState('success')
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(100)
  const [paymentsCount, setPaymentsCount] = useState()
  const [filterModel, setFilterModel] = useState({})
  const [sortModel, setSortModel] = useState([])

  const getStatus = useCallback(() => (status ? status.toUpperCase() : 'UNDER_REVIEW'), [status])
  const getOffset = useCallback(() => page * pageSize, [page, pageSize])

  const onClose = () => setMessage(null)

  const showActionError = message => {
    setMessage(message)
    setMessageSeverity('error')
  }

  const showActionSuccess = message => {
    setMessage(message)
    setMessageSeverity('success')
  }

  const getFilterOptions = useCallback(() => {
    if (!filterModel?.items?.length) return

    const { columnField, operatorValue, value } = filterModel.items[0]
    if (!columnField || !operatorValue) return
    if (operatorValue !== 'isEmpty' && operatorValue !== 'isNotEmpty' && !value) return

    const parsedFilterModel = parseMuiFilterModel(filterModel)[0]
    return parsedFilterModel
  }, [filterModel])

  const loadData = useCallback(async () => {
    setPaymentsLoaded(false)

    const sort = sortModel.length
      ? sortModel.map(({ field, sort }) => ({
          columnField: field,
          sort,
        }))
      : undefined

    const { data } = await API.graphql({
      query: queries.listPayments,
      variables: {
        status: [getStatus()],
        limit: pageSize,
        offset: getOffset(),
        sort: sort,
        filter: getFilterOptions(),
      },
    })

    const payments = data?.listPayments?.payments?.map(cleanPayment)
    const count = data?.listPayments?.count

    setPaymentsCount(count)
    setPayments(payments)
    setPaymentsLoaded(true)
  }, [pageSize, sortModel, getStatus, getOffset, getFilterOptions])

  useEffect(() => {
    loadData()
  }, [page, sortModel, filterModel, loadData])

  const handleStartReview = async () => {
    setActionRunning(true)

    try {
      const result = await API.graphql({ query: mutations.startReview })
      if (result.data && result.data.startReview > -1) {
        showActionSuccess(`Review initiated for ${result.data.startReview} payments`)
      }
    } catch (response) {
      console.error(response)
      showActionError(response.errors[0].message)
    } finally {
      setActionRunning(false)
      await loadData()
    }
  }

  const handleReleasePayments = async paymentOption => {
    setActionRunning(true)

    try {
      const result = await API.graphql({
        query: mutations.releasePayments,
        variables: {
          paymentOption,
        },
      })
      if (result.data && result.data.releasePayments > -1) {
        showActionSuccess(`${result.data.releasePayments} payments released`)
      }
    } catch (response) {
      console.error(response)
      showActionError(response.errors[0].message)
    } finally {
      setActionRunning(false)
      await loadData()
    }
  }

  const onPageChange = newPage => setPage(newPage)
  const onPageSizeChange = newPageSize => setPageSize(newPageSize)
  const onFilterModelChange = newModel => setFilterModel(newModel)
  const onSortModelChange = newModel => setSortModel(newModel)

  return (
    <div className={styles.root}>
      <Message message={message} severity={messageSeverity} onClose={onClose} />

      <h2>Payments: {getStatus()}</h2>

      {payments.length > 0 && getStatus() === 'UNDER_REVIEW' ? (
        <ReleasePaymentButtons
          disabled={actionRunning}
          count={paymentStatusCounts['UNDER_REVIEW']}
          onClick={handleReleasePayments}
        />
      ) : null}

      {payments.length > 0 && getStatus() === 'OPEN' ? (
        <ReviewPaymentButtons
          disabled={actionRunning}
          count={paymentStatusCounts['OPEN']}
          onClick={handleStartReview}
        />
      ) : null}

      <PaymentsGrid
        height="100%"
        rows={payments}
        loading={!paymentsLoaded}
        onPageChange={onPageChange}
        pageSize={pageSize}
        onPageSizeChange={onPageSizeChange}
        onFilterModelChange={onFilterModelChange}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        rowCount={paymentsCount}
      />

      {!payments.length ? <h3>There are no {getStatus()} payments.</h3> : null}
    </div>
  )
}

export default Payments
