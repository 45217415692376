import React from 'react'
import { DataGrid } from '@material-ui/data-grid'
import { Link } from 'react-router-dom'

const columns = [
  {
    field: 'id',
    headerName: 'ID',
    type: 'number',
    renderCell: params => {
      return <Link to={`/payments/${params.value}`}>{params.value}</Link>
    },
    headerAlign: 'left',
    align: 'left',
  },
  { field: 'payeeId', headerName: 'Driver ID', type: 'string', flex: 1 },
  {
    field: 'amount',
    headerName: 'Amount',
    width: 150,
    type: 'number',
    valueFormatter: params => {
      return (params.value / 100).toFixed(2)
    },
  },
  { field: 'status', headerName: 'Status', type: 'string', flex: 1 },
  { field: 'updatedAt', headerName: 'Updated', type: 'dateTime', flex: 1 },
  { field: 'createdAt', headerName: 'Created', type: 'dateTime', flex: 1 },
]

export default function PaymentsGrid(props) {
  return (
    <div style={{ height: props.height, width: '100%' }}>
      <DataGrid
        rows={props.rows}
        columns={columns}
        density="compact"
        loading={props.loading}
        pagination
        paginationMode="server"
        onPageChange={props.onPageChange}
        pageSize={props.pageSize}
        onPageSizeChange={props.onPageSizeChange}
        rowCount={props.rowCount}
        sortingMode="server"
        sortModel={props.sortModel}
        onSortModelChange={props.onSortModelChange}
        filterMode="server"
        onFilterModelChange={props.onFilterModelChange}
      />
    </div>
  )
}
