import { Auth } from 'aws-amplify'
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'
import { AuthState } from '@aws-amplify/ui-components'
import { withAuthenticator as amplifyWithAuthenticator } from '@aws-amplify/ui-react'

const defaultAuthenticatorProps = {
  handleAuthStateChange: nextAuthState => {
    if (nextAuthState === AuthState.SignIn) {
      // Redirect to federated SignIn instead of rendering SignIn form
      Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })
    }
  },
}

export function withAuthenticator(Component, authenticatorProps) {
  return amplifyWithAuthenticator(Component, { ...defaultAuthenticatorProps, ...authenticatorProps })
}
