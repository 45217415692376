import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

const formatCurrency = number => {
  return `$${(number / 100).toFixed(2)}`
}

export default function PaymentSummary(props) {
  const { amount, createdAt, payeeId, payeeType, status, updatedAt } = props.payment
  return (
    <Paper align="left" style={{ padding: '2em 1em 0', marginBottom: '3em' }}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <KeyValuePair label="Amount" val={formatCurrency(amount)} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <KeyValuePair label="Status" val={status} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <KeyValuePair label="Payee ID" val={payeeId} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <KeyValuePair label="Payee Type" val={payeeType} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <KeyValuePair label="Created" val={createdAt} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <KeyValuePair label="Updated" val={updatedAt} />
        </Grid>
      </Grid>
    </Paper>
  )
}

function KeyValuePair(props) {
  return (
    <div>
      <Typography gutterBottom variant="body1" component="h2" style={{ fontWeight: 900 }}>
        {props.label}
      </Typography>
      <Typography variant="body1" color="textSecondary" component="p">
        {props.val || '-'}
      </Typography>
      <br />
    </div>
  )
}
