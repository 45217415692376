/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listPayments = /* GraphQL */ `
  query ListPayments($status: [PaymentStatus], $limit: Int, $offset: Int, $sort: [SortModel], $filter: FilterModel) {
    listPayments(status: $status, limit: $limit, offset: $offset, sort: $sort, filter: $filter) {
      payments {
        id
        createdAt
        updatedAt
        payeeId
        payeeType
        status
        amount
        paymentErrorMessage
      }
      count
    }
  }
`
export const listLedgerItems = /* GraphQL */ `
  query ListLedgerItems($paymentStatus: [PaymentStatus], $paymentId: Int, $limit: Int, $offset: Int) {
    listLedgerItems(paymentStatus: $paymentStatus, paymentId: $paymentId, limit: $limit, offset: $offset) {
      id
      createdAt
      updatedAt
      deletedAt
      actor
      referenceType
      referenceId
      category
      amount
      note
      payment {
        id
        createdAt
        updatedAt
        payeeId
        payeeType
        status
        amount
        paymentErrorMessage
      }
      paymentId
      market
    }
  }
`
export const getPayment = /* GraphQL */ `
  query GetPayment($paymentId: Int) {
    getPayment(paymentId: $paymentId) {
      id
      createdAt
      updatedAt
      payeeId
      payeeType
      status
      amount
      items {
        id
        createdAt
        updatedAt
        deletedAt
        actor
        referenceType
        referenceId
        category
        amount
        note
        paymentId
        market
      }
      paymentErrorMessage
    }
  }
`
export const getPaymentStatusCounts = /* GraphQL */ `
  query GetPaymentStatusCounts($paymentStatus: [PaymentStatus]) {
    getPaymentStatusCounts(paymentStatus: $paymentStatus) {
      OPEN
      UNDER_REVIEW
      HELD
      APPROVED
      ENQUEUED_FOR_RELEASE
      SUBMITTED
      COMPLETED
      PROCESSING
      FAILED
      CANCELLED
    }
  }
`
