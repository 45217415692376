import './App.css'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import Payments from './views/Payments'
import AddLedgerItem from './views/AddLedgerItem'
import PaymentDetails from './views/PaymentDetails'
import ClippedDrawer from './components/ClippedDrawer'
import { withAuthenticator } from './components/withAuthenticator'

function App() {
  return (
    <Router>
      <div className="App">
        <ClippedDrawer>
          <Switch>
            <Route exact path="/payments">
              <Redirect to="/payments/status/under_review" />
            </Route>
            <Route path="/payments/status/:status" render={props => <Payments status={props.match.params.status} />} />
            <Route
              path="/payments/:paymentId/add-ledger-item"
              render={props => <AddLedgerItem paymentId={parseInt(props.match.params.paymentId, 10)} />}
            />
            <Route path="/payments/:paymentId">
              <PaymentDetails />
            </Route>
            <Route exact path="/add-ledger-item">
              <AddLedgerItem />
            </Route>
            <Route path="/">
              <Redirect to="/payments" />
            </Route>
          </Switch>
        </ClippedDrawer>
      </div>
    </Router>
  )
}
export { App as UnwrappedApp }

export default withAuthenticator(App)
