import { useEffect, useState } from 'react'
import { API } from 'aws-amplify'

import { Queries } from '../graphql'

export const usePaymentStatusCounts = () => {
  const [paymentStatusCounts, setPaymentStatusCounts] = useState({})

  function getPaymentStatusCounts() {
    return API.graphql({
      query: Queries.getPaymentStatusCounts,
      variables: {
        paymentStatus: [
          'OPEN',
          'UNDER_REVIEW',
          'HELD',
          'APPROVED',
          'ENQUEUED_FOR_RELEASE',
          'SUBMITTED',
          'COMPLETED',
          'PROCESSING',
          'FAILED',
          'CANCELLED',
        ],
      },
    })
      .then(({ data }) => {
        setPaymentStatusCounts(data.getPaymentStatusCounts)
      })
      .catch(error => {
        console.warn(error)
      })
  }

  useEffect(() => {
    let paymentStatusTimeout
    // Run immediately
    getPaymentStatusCounts().then(() => {
      // ... then every 30s
      paymentStatusTimeout = setInterval(getPaymentStatusCounts, 30000, [])
    })
    // Clean up
    return () => {
      clearInterval(paymentStatusTimeout)
    }
  }, [])

  return paymentStatusCounts
}
