import { DateTime } from 'luxon'

export function parseMuiFilterOperationForSql(operation) {
  switch (operation) {
    case '=':
    case 'equals':
      return '='

    case '!=':
      return '!='

    case '>':
    case 'after':
      return '>'

    case '>=':
    case 'onOrAfter':
      return '>='

    case '<':
    case 'before':
      return '<'

    case '<=':
    case 'onOrBefore':
      return '<='

    // These dateTime 'is' and 'not' filters _would_ be a one-to-one operation BUT Mui does not
    // support dateTime selection to the seconds resolution, use the BETWEEN SQL function instead
    case 'is':
      return 'between'

    case 'not':
      return 'notBetween'

    // Some Mui string filter operations are not a one-to-one mapping to SQL, pass them through.
    // Operations include: contains, startsWith, endsWith, isEmpty, and isNotEmpty
    default:
      return operation
  }
}

export const parseMuiFilterValueForType = ({ operatorValue, columnField, value }) => {
  // Special case, take advantage of date value being in the format of "YYYY-MM-DDDTHH:MM" and append the seconds
  if (operatorValue === 'is' || operatorValue === 'not') {
    return {
      dateRange: {
        startDate: DateTime.fromISO(`${value}:00`).toISO(),
        endDate: DateTime.fromISO(`${value}:59`).toISO(),
      },
    }
  }

  switch (columnField) {
    case 'id':
      return { numberValue: value }

    case 'amount':
      return { numberValue: value * 100 }

    case 'updatedAt':
    case 'createdAt':
      return { dateValue: DateTime.fromISO(value).toISO() }

    case 'payeeId':
    case 'status':
    default:
      return { stringValue: value }
  }
}

export const parseMuiFilterModel = filterModel =>
  filterModel?.items?.map(({ columnField, value, operatorValue, ...rest }) => ({
    columnField,
    operatorValue: parseMuiFilterOperationForSql(operatorValue),
    ...parseMuiFilterValueForType({ operatorValue, columnField, value }),
  }))
