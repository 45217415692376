import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import Amplify from 'aws-amplify'
import awsExports from './aws-exports'
import '@fontsource/roboto'

const REDIRECT_DOMAINS = {
  local: 'http://localhost:3000',
  dev: 'https://seshat.app.dev.shipveho.com',
  staging: 'https://seshat.app.staging.shipveho.com',
  prod: 'https://seshat.app.shipveho.com',
}

Amplify.configure({
  ...awsExports,
  // AppSync API config
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_APP_SYNC_API,
  aws_appsync_region: awsExports.aws_project_region,
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  // Auth config
  aws_cognito_identity_pool_id: process.env.REACT_APP_APP_SYNC_API_IDENTITY_POOL_ID,
  aws_cognito_region: awsExports.aws_project_region,
  aws_user_pools_id: process.env.REACT_APP_APP_SYNC_API_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_APP_SYNC_API_USER_POOL_CLIENT_ID,
  oauth: {
    domain: process.env.REACT_APP_APP_SYNC_API_USER_POOL_DOMAIN,
    scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: REDIRECT_DOMAINS[process.env.REACT_APP_STAGE || 'local'],
    redirectSignOut: REDIRECT_DOMAINS[process.env.REACT_APP_STAGE || 'local'],
    clientId: process.env.REACT_APP_APP_SYNC_API_USER_POOL_CLIENT_ID,
    responseType: 'code',
    label: 'Sign In with Veho',
  },
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
