import { API } from 'aws-amplify'
import React, { useState, useEffect, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TransactionsGrid from '../components/TransactionsGrid'
import PaymentSummary from '../components/PaymentSummary'
import Message from '../components/Message'
import { Queries, Mutations } from '../graphql'
import { ACH_EXPEDITED, ACH_NEXT_DAY, ACH_SAME_DAY } from '../config/enum/Constant'

const getPaymentDetails = async function ({ paymentId }) {
  return await API.graphql({
    query: Queries.getPayment,
    variables: {
      paymentId,
    },
  })
}

function AddLedgerItemButton(props) {
  const history = useHistory()

  function handleClick() {
    history.push(`/payments/${props.paymentId}/add-ledger-item/`)
  }

  return (
    <Button type="button" variant="outlined" color="primary" display="inline" mt={2} onClick={handleClick}>
      Add Ledger Item
    </Button>
  )
}

function ReleasePaymentButtons({ onClick, disabled }) {
  const [paymentOption, setPaymentOption] = React.useState(ACH_EXPEDITED)

  const handlePaymentOption = event => {
    setPaymentOption(event.target.value)
  }

  return (
    <Grid container item spacing={1} alignItems="center">
      <Grid item>
        <Button variant="contained" color="primary" disabled={disabled} onClick={() => onClick(paymentOption)}>
          Release
        </Button>
      </Grid>
      <Grid item>
        <Select value={paymentOption} onChange={handlePaymentOption}>
          <MenuItem value={ACH_EXPEDITED}>ACH: 2-3 business days</MenuItem>
          <MenuItem value={ACH_NEXT_DAY}>ACH: 1 business day</MenuItem>
          <MenuItem value={ACH_SAME_DAY}>ACH: Same business day</MenuItem>
        </Select>
      </Grid>
    </Grid>
  )
}

export default function PaymentDetails() {
  const params = useParams()
  const paymentId = parseInt(params.paymentId, 10)
  const [paymentData, setPaymentData] = useState({})
  const [actionRunning, setActionRunning] = useState(false)
  const [message, setMessage] = useState({ message: null })

  const fetchData = useCallback(async () => {
    setActionRunning(true)
    const result = await getPaymentDetails({ paymentId: parseInt(paymentId) })

    const { data, errors } = result
    if (errors) {
      console.error(errors)
    }

    if (data && data.getPayment) {
      setPaymentData(data.getPayment)
    }
    setActionRunning(false)
  }, [setPaymentData, paymentId])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const showError = message => {
    setMessage({
      messageSeverity: 'error',
      message,
    })
  }

  const showSuccess = message => {
    setMessage({
      messageSeverity: 'success',
      message,
    })
  }

  const updatePayment = async ({ operation, query, variables, optimisticUpdates, reload = false }) => {
    setActionRunning(true)
    if (optimisticUpdates) {
      setPaymentData({ ...paymentData, ...optimisticUpdates })
    }

    try {
      const { data, errors } = await API.graphql({
        query,
        variables,
      })

      if (errors) {
        showError(errors[0]?.message)
        console.error(errors)
      }

      if (reload) {
        // Reload instead of using returned data
        fetchData()
      } else if (data[operation]) {
        // Update with returned data
        setPaymentData({ ...data[operation] })
      }
      showSuccess('Success')
    } catch (response) {
      console.error(response)
      showError(response.errors[0]?.message)
      fetchData()
    } finally {
      setActionRunning(false)
    }
  }

  const handleHoldPayment = async () => {
    await updatePayment({
      operation: 'holdPayment',
      query: Mutations.holdPayment,
      variables: {
        paymentId,
      },
      optimisticUpdates: { status: 'HELD' },
    })
  }

  const handleUnholdPayment = async () => {
    await updatePayment({
      operation: 'unholdPayment',
      query: Mutations.unholdPayment,
      variables: {
        paymentId,
      },
      optimisticUpdates: { status: 'UNDER_REVIEW' },
    })
  }

  const handleReviewPayment = async () => {
    await updatePayment({
      operation: 'startReview',
      query: Mutations.startReview,
      variables: {
        paymentId,
      },
      optimisticUpdates: { status: 'UNDER_REVIEW' },
      reload: true,
    })
  }

  const handleReleasePayment = async paymentOption => {
    await updatePayment({
      operation: 'releasePayments',
      query: Mutations.releasePayments,
      variables: {
        paymentId,
        paymentOption,
      },
      optimisticUpdates: { status: 'APPROVED' },
      reload: true,
    })
  }

  const handleRequeuePayment = async () => {
    await updatePayment({
      operation: 'requeuePayment',
      query: Mutations.requeuePayment,
      variables: {
        paymentId,
      },
      optimisticUpdates: { status: 'ENQUEUED_FOR_RELEASE' },
    })
  }

  const handleStuckInSubmittedPayment = async () => {
    await updatePayment({
      operation: 'updateStatusFromSubmittedToFailed',
      query: Mutations.updateStatusFromSubmittedToFailed,
      variables: {
        paymentId,
      },
      optimisticUpdates: { status: 'FAILED' },
    })
  }

  if (!Object.keys(paymentData).length) {
    return (
      <div>
        <h2>Payment #{paymentId}</h2>
        <CircularProgress color="inherit" />
      </div>
    )
  }

  return (
    <div>
      <h2>Payment #{paymentId}</h2>

      <Message
        message={message.message}
        severity={message.messageSeverity}
        onClose={() => setMessage({ message: null })}
      />

      <Grid container justify="flex-end" alignItems="center" spacing={3} style={{ marginBottom: '1em' }}>
        {['UNDER_REVIEW', 'HELD'].includes(paymentData.status) ? (
          <Grid item>
            <AddLedgerItemButton paymentId={paymentId} />
          </Grid>
        ) : null}
        {paymentData.status === 'OPEN' && (
          <Grid item>
            <Button variant="outlined" color="primary" onClick={handleReviewPayment} disabled={actionRunning}>
              Start Review
            </Button>
          </Grid>
        )}
        {paymentData.status === 'UNDER_REVIEW' && (
          <Grid item>
            <Button variant="outlined" color="secondary" onClick={handleHoldPayment} disabled={actionRunning}>
              Hold
            </Button>
          </Grid>
        )}
        {paymentData.status === 'UNDER_REVIEW' && (
          <Grid item>
            <ReleasePaymentButtons
              onClick={paymentOption => handleReleasePayment(paymentOption)}
              disabled={actionRunning}
            >
              Release
            </ReleasePaymentButtons>
          </Grid>
        )}
        {paymentData.status === 'HELD' && (
          <Grid item>
            <Button variant="outlined" color="primary" onClick={handleUnholdPayment} disabled={actionRunning}>
              Unhold
            </Button>
          </Grid>
        )}
        {(paymentData.status === 'ENQUEUED_FOR_RELEASE' || paymentData.status === 'FAILED') && (
          <Grid item>
            <Button variant="outlined" color="primary" onClick={handleRequeuePayment} disabled={actionRunning}>
              Re-Queue
            </Button>
          </Grid>
        )}
        {paymentData.status === 'SUBMITTED' && (
          <Grid item>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleStuckInSubmittedPayment}
              disabled={actionRunning}
            >
              Update to Failed
            </Button>
          </Grid>
        )}
      </Grid>

      <PaymentSummary payment={paymentData} />

      <h3>Ledger Items</h3>
      {paymentData.items && <TransactionsGrid height="40vh" rows={paymentData.items} />}
    </div>
  )
}
