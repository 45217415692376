import React, { useCallback } from 'react'
import { API } from 'aws-amplify'
import * as mutations from '../graphql/mutations'
import AddLedgerItemForm from '../components/AddLedgerItemForm'
import { ActionAlert } from '../components/Alert'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import { useDropzone } from 'react-dropzone'
import { parse as csvParse } from 'papaparse'
import { DataGrid } from '@material-ui/data-grid'

const BULK_IMPORT_GRID_COLUMNS = [
  { field: 'id', headerName: 'ID', type: 'number', hide: true },
  { field: 'driver_id', headerName: 'Driver ID', type: 'string', editable: true, flex: 1 },
  {
    field: 'amount',
    headerName: 'Amount',
    type: 'number',
    editable: true,
    flex: 1,
    valueFormatter: params => {
      return parseFloat(params.value).toFixed(2)
    },
  },
  { field: 'category', headerName: 'Category', type: 'string', editable: true, flex: 1 },
  { field: 'reference_type', headerName: 'Ref Type', type: 'string', editable: true, flex: 1 },
  { field: 'reference_id', headerName: 'Ref ID', type: 'string', editable: true, flex: 1 },
  { field: 'market', headerName: 'Market', type: 'string', editable: true, flex: 1 },
  { field: 'note', headerName: 'Note', type: 'string', editable: true, flex: 1 },
  {
    field: 'save_result',
    headerName: 'Status',
    type: 'string',
    editable: false,
    flex: 1,
    valueFormatter: params => {
      if (!params.value) {
        return 'NEW'
      }
      return params.value['success'] ? 'SAVED' : params.value['error']
    },
    renderCell: params => {
      const cell = <span className="table-cell-truncate">{params.formattedValue}</span>
      return params.value && params.value['error'] ? <Tooltip title={params.formattedValue}>{cell}</Tooltip> : cell
    },
  },
]

function CsvDropper(props) {
  const onDrop = useCallback(
    acceptedFiles => {
      acceptedFiles.forEach(file => {
        const reader = new FileReader()

        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = () => {
          props.onFileLoaded(
            csvParse(reader.result, { header: true }).data.filter(x => {
              return !!x['amount'] && !!x['category'] && !!x['driver_id']
            })
          )
        }
        reader.readAsText(file)
      })
    },
    [props]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  })

  return (
    <div style={{ border: '2px dotted black' }} {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>C'mon... do it... what are you waiting for?!</p>
      ) : (
        <p>Drag and drop a Ledger Item CSV, or click to select the file</p>
      )}
    </div>
  )
}

export default class AddLedgerItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ledgerItemId: null,
      errorList: [],
      loading: false,
      category: 'MISC',
      amount: null,
      referenceType: 'NONE',
      referenceId: null,
      note: null,
      market: null,
    }
  }

  submitForm = async () => {
    this.setState({ loading: true })

    try {
      const result = await API.graphql({
        query: mutations.addLedgerItemToPayment,
        variables: {
          paymentId: this.props.paymentId,
          category: this.state.category,
          amount: this.state.amount,
          referenceType: this.state.referenceType,
          referenceId: this.state.referenceId,
          note: this.state.note,
          market: this.state.market,
        },
      })

      if (result.data && result.data.addLedgerItemToPayment) {
        this.setState({
          ledgerItemId: result.data.addLedgerItemToPayment.id,
          category: 'MISC',
          amount: null,
          referenceType: 'NONE',
          referenceId: null,
          note: null,
          market: null,
        })
      }
    } catch (error) {
      this.setState({ errorList: error.errors.map(e => e.message) })
    } finally {
      this.setState({ loading: false })
    }
  }

  updateForm = ({ name, value }) => {
    this.setState({
      [name]: value,
    })
  }

  dismissErrorMessage = () => {
    this.setState({ errorList: [] })
  }

  dismissSuccessMessage = () => {
    this.setState({ ledgerItemId: null })
  }

  onFileLoaded = importData => {
    this.setState(prevState => {
      return {
        ...prevState,
        importData: importData.map((v, i) => {
          v['id'] = i
          return v
        }),
      }
    })
  }

  onCancelImport = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        importData: null,
      }
    })
  }

  onClearCompleted = () => {
    this.setState(prevState => {
      const remaining = prevState.importData.filter(x => !x.save_result || !x.save_result.success)
      return {
        ...prevState,
        importData: remaining.length ? remaining : null,
      }
    })
  }

  onSaveImport = async () => {
    this.setState({ loading: true })

    try {
      for (const item of this.state.importData) {
        const saveResult = item.save_result || {}
        try {
          if (saveResult['success']) {
            continue
          }
          const result = await API.graphql({
            query: mutations.addLedgerItemByPaymentStatus,
            variables: {
              driverId: item['driver_id'],
              status: 'UNDER_REVIEW',
              category: item['category'],
              amount: item['amount'],
              referenceType: item['reference_type'] || 'NONE',
              referenceId: item['reference_id'],
              note: item['note'],
              market: item['market'],
            },
          })

          if (result.data && result.data.addLedgerItemByPaymentStatus) {
            saveResult['success'] = true
          }
        } catch (ex) {
          // this.setState({ errorList: [ex.message] })
          saveResult['error'] = ex.errors.map(e => e.message).join(' \n')
        } finally {
          this.setState(prevState => {
            return {
              importData: prevState.importData.map(v => {
                if (v['id'] === item['id']) {
                  v = {
                    ...v,
                    save_result: saveResult,
                  }
                }
                return v
              }),
            }
          })
        }
      }
    } catch (ex) {
      this.setState({ errorList: [ex.message] })
    } finally {
      // this.setState({ importData: null })
      this.setState({ loading: false })
    }
  }

  onEditCellChange = change => {
    this.setState(prevState => {
      return {
        ...prevState,
        importData: prevState.importData.map(v => {
          if (v['id'] === change['id']) {
            v = {
              ...v,
              [change['field']]: change['props']['value'],
            }
          }
          return v
        }),
      }
    })
  }

  renderBulkImport(props) {
    return (
      <div style={{ width: '100%' }}>
        <Grid container spacing={3}>
          <Grid item lg={8} md={8} sm={8} xs={8} justify="flex-start" style={{ textAlign: 'left' }}>
            <Button
              type="submit"
              variant="outlined"
              color="secondary"
              display="inline"
              mt={2}
              onClick={this.onCancelImport}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={2} justify="flex-end">
            <Button type="submit" variant="outlined" display="inline" mt={2} onClick={this.onClearCompleted}>
              Clear Completed
            </Button>
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={2} justify="flex-end">
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              display="inline"
              mt={2}
              onClick={this.onSaveImport}
            >
              Save Ledger Items
            </Button>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} style={{ height: '80vh' }}>
            <DataGrid
              rows={this.state.importData}
              columns={BULK_IMPORT_GRID_COLUMNS}
              // checkboxSelection
              density="compact"
              onEditCellChange={this.onEditCellChange}
            />
          </Grid>
        </Grid>
      </div>
    )
  }

  render() {
    const showImportGrid = this.state.importData
    const showBulkDrop = !this.state.importData && !this.props.paymentId
    const showForm = !this.state.importData && this.props.paymentId
    return (
      <div>
        {showImportGrid ? this.renderBulkImport() : null}
        {showBulkDrop ? (
          <Grid container spacing={1} justify="center" alignItems="center">
            <Grid item lg={6} md={8} sm={12} xs={12}>
              <Paper elevation={6} style={{ padding: '2em' }}>
                <CsvDropper onFileLoaded={this.onFileLoaded} />
              </Paper>
            </Grid>
          </Grid>
        ) : null}
        {showForm ? (
          <Grid container spacing={1} justify="center" alignItems="center">
            <Grid item lg={6} md={8} sm={12} xs={12}>
              <Paper elevation={6} style={{ padding: '2em' }}>
                <h2>Add Ledger Item To Payment #{this.props.paymentId}</h2>

                {/* <div>Adding item: {this.state.loading ? 'true' : 'false'}</div> */}

                {this.state.errorList.length > 0 && (
                  <ActionAlert onClose={this.dismissErrorMessage} severity="error" message={this.state.errorList[0]} />
                )}

                {this.state.ledgerItemId && (
                  <ActionAlert
                    onClose={this.dismissSuccessMessage}
                    severity="success"
                    message={`Ledger item ${this.state.ledgerItemId} added!`}
                  />
                )}
                <AddLedgerItemForm
                  updateForm={this.updateForm}
                  submitForm={this.submitForm}
                  paymentId={this.props.paymentId}
                  category={this.state.category}
                  amount={this.state.amount}
                  referenceType={this.state.referenceType}
                  referenceId={this.state.referenceId}
                  note={this.state.note}
                  market={this.state.market}
                />
              </Paper>
            </Grid>
          </Grid>
        ) : null}
      </div>
    )
  }
}
