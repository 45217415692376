/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const holdPayment = /* GraphQL */ `
  mutation HoldPayment($paymentId: Int!) {
    holdPayment(paymentId: $paymentId) {
      id
      createdAt
      updatedAt
      payeeId
      payeeType
      status
      amount
      items {
        id
        createdAt
        updatedAt
        deletedAt
        actor
        referenceType
        referenceId
        category
        amount
        note
        paymentId
        market
      }
      paymentErrorMessage
    }
  }
`
export const unholdPayment = /* GraphQL */ `
  mutation UnholdPayment($paymentId: Int!) {
    unholdPayment(paymentId: $paymentId) {
      id
      createdAt
      updatedAt
      payeeId
      payeeType
      status
      amount
      items {
        id
        createdAt
        updatedAt
        deletedAt
        actor
        referenceType
        referenceId
        category
        amount
        note
        paymentId
        market
      }
      paymentErrorMessage
    }
  }
`
export const requeuePayment = /* GraphQL */ `
  mutation RequeuePayment($paymentId: Int!) {
    requeuePayment(paymentId: $paymentId) {
      id
      createdAt
      updatedAt
      payeeId
      payeeType
      status
      amount
      items {
        id
        createdAt
        updatedAt
        deletedAt
        actor
        referenceType
        referenceId
        category
        amount
        note
        paymentId
        market
      }
      paymentErrorMessage
    }
  }
`
export const startReview = /* GraphQL */ `
  mutation StartReview($paymentId: Int) {
    startReview(paymentId: $paymentId)
  }
`
export const releasePayments = /* GraphQL */ `
  mutation ReleasePayments($paymentOption: PaymentOption, $paymentId: Int) {
    releasePayments(paymentOption: $paymentOption, paymentId: $paymentId)
  }
`
export const addLedgerItem = /* GraphQL */ `
  mutation AddLedgerItem(
    $driverId: String!
    $category: LedgerCategory!
    $amount: Float!
    $referenceType: LedgerRefType
    $referenceId: String
    $note: String
    $market: String
  ) {
    addLedgerItem(
      driverId: $driverId
      category: $category
      amount: $amount
      referenceType: $referenceType
      referenceId: $referenceId
      note: $note
      market: $market
    ) {
      id
      createdAt
      updatedAt
      deletedAt
      actor
      referenceType
      referenceId
      category
      amount
      note
      payment {
        id
        createdAt
        updatedAt
        payeeId
        payeeType
        status
        amount
        paymentErrorMessage
      }
      paymentId
      market
    }
  }
`
export const addLedgerItemByPaymentStatus = /* GraphQL */ `
  mutation AddLedgerItemByPaymentStatus(
    $status: EditablePaymentStatus!
    $driverId: String!
    $category: LedgerCategory!
    $amount: Float!
    $referenceType: LedgerRefType
    $referenceId: String
    $note: String
    $market: String
  ) {
    addLedgerItemByPaymentStatus(
      status: $status
      driverId: $driverId
      category: $category
      amount: $amount
      referenceType: $referenceType
      referenceId: $referenceId
      note: $note
      market: $market
    ) {
      id
      createdAt
      updatedAt
      deletedAt
      actor
      referenceType
      referenceId
      category
      amount
      note
      payment {
        id
        createdAt
        updatedAt
        payeeId
        payeeType
        status
        amount
        paymentErrorMessage
      }
      paymentId
      market
    }
  }
`
export const addLedgerItemToPayment = /* GraphQL */ `
  mutation AddLedgerItemToPayment(
    $paymentId: Int!
    $category: LedgerCategory!
    $amount: Float!
    $referenceType: LedgerRefType
    $referenceId: String
    $note: String
    $market: String
  ) {
    addLedgerItemToPayment(
      paymentId: $paymentId
      category: $category
      amount: $amount
      referenceType: $referenceType
      referenceId: $referenceId
      note: $note
      market: $market
    ) {
      id
      createdAt
      updatedAt
      deletedAt
      actor
      referenceType
      referenceId
      category
      amount
      note
      payment {
        id
        createdAt
        updatedAt
        payeeId
        payeeType
        status
        amount
        paymentErrorMessage
      }
      paymentId
      market
    }
  }
`
export const mapDriverToVendor = /* GraphQL */ `
  mutation MapDriverToVendor($driverId: String!, $vendorId: String!, $shouldReplace: Boolean) {
    mapDriverToVendor(driverId: $driverId, vendorId: $vendorId, shouldReplace: $shouldReplace)
  }
`
export const updateStatusFromSubmittedToFailed = /* GraphQL */ `
  mutation UpdateStatusFromSubmittedToFailed($paymentId: Int!) {
    updateStatusFromSubmittedToFailed(paymentId: $paymentId) {
      id
      createdAt
      updatedAt
      payeeId
      payeeType
      status
      amount
      items {
        id
        createdAt
        updatedAt
        deletedAt
        actor
        referenceType
        referenceId
        category
        amount
        note
        paymentId
        market
      }
      paymentErrorMessage
    }
  }
`
